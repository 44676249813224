import type { ProductForCustomer } from "../Product.js";
import { ProductOption, restrictedProductOptions } from "../ProductOption.js";
import { ProductSize } from "../ProductSize.js";

export function isProductForCustomerConfigurable(
	{ enabledOptions }: Pick<ProductForCustomer, "enabledOptions">,
	includeRestrictedOptions: boolean,
	size?: ProductSize,
): boolean {
	return enabledOptions.some((enabledOption: ProductOption) => {
		if (!includeRestrictedOptions && restrictedProductOptions.includes(enabledOption)) {
			return false;
		}
		// SD-545 – no lactose free variant for extra small products
		if (size !== undefined && enabledOption === ProductOption.LactoseFreeVariant && size === ProductSize.Xs) {
			return false;
		}
		return true;
	});
}
