import { Locale, type LocalizedString } from "./Locale.js";

export enum ProductFigurineStatus {
	ToDo = "ToDo",
	Ready = "Ready",
}

export const productFigurineStatusNameMap: Record<ProductFigurineStatus, LocalizedString> = {
	[ProductFigurineStatus.ToDo]: { [Locale.cs]: "TODO" },
	[ProductFigurineStatus.Ready]: { [Locale.cs]: "Připravená" },
};
