import { isEnum } from "../../utils/enum/isEnum.js";
import type { Address } from "../Address.js";
import { Branch } from "../Branch.js";
import { TaxLevel } from "../TaxLevel.js";
import { calculatePriceWithoutTax } from "./calculatePriceWithoutTax.js";
import { getDeliveryDetails } from "./getDeliveryZone.js";

const isBranch = isEnum(Branch);

export function getPriceOfDelivery(
	priceWithoutDelivery: number,
	destination: Branch | Address,
): { withTax: number; withoutTax: number } | "unreachable" | null {
	if (isBranch(destination)) {
		return {
			withTax: 0,
			withoutTax: 0,
		};
	}

	const deliveryDetails = getDeliveryDetails(destination);
	if (deliveryDetails === null) {
		return null;
	}
	if (deliveryDetails === "unreachable") {
		return "unreachable";
	}

	if (priceWithoutDelivery >= deliveryDetails.freeDeliveryFrom) {
		return {
			withTax: 0,
			withoutTax: 0,
		};
	}

	const withTax = deliveryDetails.priceWithTax;
	return {
		withTax,
		withoutTax: calculatePriceWithoutTax(withTax, TaxLevel.TaxLevel21),
	};
}
