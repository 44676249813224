import { ProductOption, type ProductOptionValueTypes } from "../ProductOption.js";

export function isProductOptionUsed<Option extends ProductOption>(
	option: Option,
	value: ProductOptionValueTypes[Option],
): value is Exclude<ProductOptionValueTypes[Option], undefined> {
	const truthMap: {
		[Option in ProductOption]: (option: ProductOptionValueTypes[Option]) => boolean;
	} = {
		[ProductOption.CreamColor]: (value) => value !== undefined,
		[ProductOption.ExtraFruit]: (value) => value,
		[ProductOption.Figurines]: (value) => value.length > 0,
		[ProductOption.Filling]: (value) => value !== undefined,
		[ProductOption.GlutenFreeVariant]: (value) => value,
		[ProductOption.Inscription]: (value) => value !== undefined,
		[ProductOption.Labels]: (value) => value.length > 0,
		[ProductOption.LactoseFreeVariant]: (value) => value,
		[ProductOption.LyingPhoto]: (value) => value !== undefined,
		[ProductOption.Macaroons]: (value) => value !== undefined,
		[ProductOption.Meringues]: (value) => value,
		[ProductOption.Numbers]: (value) => value.length > 0,
		[ProductOption.StandingPhotos]: (value) => value.length > 0,
	};
	return truthMap[option](value);
}
