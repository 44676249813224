<script lang="ts">
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import Icon from "./Icon.svelte";
	import { nbspify } from "../../../core/utils/nbspify";
	import desserts from "../../../core/assets/icons/desserts.svg?raw";
	import cake from "../../../core/assets/icons/cake-lg.svg?raw";
	import sladkaDilna from "../../../core/assets/icons/sladka-dilna.svg?raw";
	import type { Step } from "../../../core/schema/Step";

	export let headline = "Jak to funguje?";
	export let steps: Step[] = [];

	const defaultSteps: Step[] = [
		{
			title: "Objednejte si dobroty, na které máte chuť",
			description: "Oslava nebo jen setkání s přáteli? Vyberte si z naší široké nabídky to, co vám padne do oka.",
			icon: desserts,
		},
		{
			title: "My v dílně vše s láskou napečeme podle vašich představ",
			description: "Velkou většinu stíháme napéct už do druhého dne.",
			icon: cake,
		},
		{
			title: "Připravenou krabičku si u nás osobně vyzvednete",
			description: "Přichystáme ji pro vás na vyzvednutí v naší cukrárně v Čakovicích nebo na Petřinách.",
			icon: sladkaDilna,
		},
	];

	$: steps = steps.length > 0 ? steps : defaultSteps;
	/* eslint-disable svelte/no-at-html-tags */
</script>

<div class="py-16 lg:pt-8">
	<h2 class="decorative-headline">{headline}</h2>
	<div class="mt-12 flex flex-col items-center gap-24 lg:flex-row lg:items-start lg:justify-between lg:gap-8 xl:gap-16">
		{#each steps as step, index}
			<div
				class="relative flex max-w-[20rem] flex-col items-center text-center lg:max-w-[initial] lg:basis-[calc((100%-(2*4rem))/3)]"
			>
				<Icon class="mb-6 h-20 w-20 lg:mb-8 lg:h-[5.5rem] lg:w-[5.5rem]" icon={step.icon} />
				<div class="flex flex-col items-center gap-4 text-center">
					<p class="text-secondary text-xs font-semibold uppercase lg:text-base">{index + 1}. krok</p>
					<p class="text-md font-semibold leading-[1.35] lg:text-lg">{@html nbspify(step.title)}</p>
					<p class="text-xs lg:text-base">{@html nbspify(step.description)}</p>
				</div>
				{#if index < 2}
					<Icon
						class="absolute bottom-[-4.5rem] left-0 right-0 mx-auto w-12 rotate-90 lg:bottom-[initial] lg:left-[initial] lg:right-[-5rem] lg:top-0 lg:mx-0 lg:w-[6.5rem] lg:rotate-0"
						icon={arrowRightLong}
					/>
				{/if}
			</div>
		{/each}
	</div>
</div>
