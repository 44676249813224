import { roundToPlaces } from "../../utils/roundToPlaces.js";
import type { OrderProduct } from "../OrderProduct.js";
import { ProductOption, productOptionKeyMap } from "../ProductOption.js";
import { calculatePriceForProductOption } from "./calculatePriceForProductOption.js";
import { calculatePriceWithoutTax } from "./calculatePriceWithoutTax.js";

export function getPriceOfOrderProduct({ product, quantity, options }: OrderProduct): {
	withTax: number;
	withoutTax: number;
} {
	const baseWithTax = product.price.withTax;

	if (!options) {
		const totalWithTax = baseWithTax * quantity;
		return {
			withTax: roundToPlaces(totalWithTax, 2),
			withoutTax: calculatePriceWithoutTax(totalWithTax, product.price.taxLevel),
		};
	}

	const priceOfOptions = Object.values(ProductOption).reduce((acc, option) => {
		const optionKey = productOptionKeyMap[option];
		if (!(optionKey in options)) {
			return acc;
		}

		const value = options[optionKey];

		return acc + calculatePriceForProductOption(baseWithTax, option, value);
	}, 0);

	const totalWithTax = (baseWithTax + priceOfOptions) * quantity;
	return {
		withTax: roundToPlaces(totalWithTax, 2),
		withoutTax: calculatePriceWithoutTax(totalWithTax, product.price.taxLevel),
	};
}
