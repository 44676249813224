export interface DeliveryDetails {
	priceWithTax: number;
	freeDeliveryFrom: number;
}

export const deliveryInfoPerZoneMap = {
	1: {
		priceWithTax: 250,
		freeDeliveryFrom: 4000,
	},
	2: {
		priceWithTax: 350,
		freeDeliveryFrom: 5000,
	},
	3: {
		priceWithTax: 450,
		freeDeliveryFrom: 6000,
	},
} satisfies Record<number, DeliveryDetails>;
