<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import bannerHeadlineSladkaImg from "../assets/images/sladka.svg";
	import bannerHeadlineDilnaImg from "../assets/images/dilna.svg";
	import cake from "../../../core/assets/icons/cake.svg?raw";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify";
	import posterImage from "../assets/images/valentines/vanilkove-srdce-cervene.webp";
	import videoHpWebm from "../assets/images/valentines/hp-valentines.webm";
	import { getGlobalSettings } from "../getGlobalSettings";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import { categoryPathMap } from "../../../core/schema/paths/categoryPathMap.js";

	const { TENANT_LOCALE } = getGlobalSettings();
	/* eslint-disable svelte/no-at-html-tags */
</script>

<div
	class="flex flex-col items-center gap-14 pb-20 pt-6 lg:flex-row lg:items-start lg:justify-center lg:gap-8 lg:pb-28 xl:gap-12"
>
	<div class="z-10 flex flex-col items-center text-center lg:basis-[calc(40%-1rem)] lg:items-start lg:text-left">
		<h1 class="m-0 pt-0 xl:pt-12">
			<span class="decorative-headline block lg:text-left">Valentýnská</span>
			<span class="mb-8 flex flex-col items-center gap-4 sm:mb-10 sm:gap-[1.46rem] lg:items-start">
				<StaticPicture
					class="max-w-[19rem] sm:max-w-[25rem] lg:max-w-[23rem] xl:max-w-[30rem]"
					image={bannerHeadlineSladkaImg}
					alt="Nápis Sladká"
					height={185}
					width={465}
					loading="eager"
				/>
				<StaticPicture
					class="max-w-[15rem] sm:max-w-[21rem] lg:max-w-[19rem] xl:max-w-[26rem]"
					image={bannerHeadlineDilnaImg}
					alt="Nápis dílna"
					height={185}
					width={366}
					loading="eager"
				/>
			</span>
		</h1>
		<StaticVideo
			class="aspect-square h-[20rem] overflow-hidden rounded-lg sm:h-[25rem] lg:hidden"
			video={videoHpWebm}
			poster={posterImage}
			loading="eager"
		/>
		<Link
			class="sm:!text-md mt-6 !rounded-full !px-8 !py-2 sm:mt-10 sm:!px-12 sm:!py-4 lg:order-2 xl:!py-[1.125rem] xl:!text-[1.375rem] 2xl:!px-20"
			href="/{categoryPathMap.valentines[TENANT_LOCALE]}"
			icon={cake}
			iconClass="w-5"
			variant="primary"
			asButton
		>
			Valentýnské dobroty
		</Link>
		<p class="mt-6 lg:order-1 lg:mt-0">
			{@html nbspify(
				"<span class='font-semibold'>Láska prochází žaludkem.</span> Překvapte svou drahou polovičku lahodným dortem, který potěší nejen chuťové pohárky, ale i srdce. Udělejte letošní Valentýn nezapomenutelným – <span class='font-semibold'>objednejte originální romantický dárek ještě dnes</span>.",
			)}
		</p>
	</div>
	<StaticVideo
		class="hidden aspect-square overflow-hidden rounded-lg lg:mt-12 lg:block lg:h-[28rem] xl:mt-0 xl:h-[37.5rem]"
		video={videoHpWebm}
		poster={posterImage}
		loading="eager"
	/>
</div>
