export enum CreamColor {
	Blue = "Blue",
	Green = "Green",
	White = "White",
	Red = "Red",
	Pink = "Pink",
	Purple = "Purple",
	Gray = "Gray",
	Yellow = "Yellow",
}
