import { PaymentType } from "../../../../core/schema/PaymentType.js";
import { PriceLevel } from "../../../../core/schema/PriceLevel.js";
import { deliveryTypeToBranchMap } from "../../../../core/schema/deliveryTypeToBranchMap.js";
import { getAppliedVoucherValue } from "../../../../core/schema/utils/getAppliedVoucherValue.js";
import { getCantPayOnDeliveryReasons } from "../../../../core/schema/utils/getCantPayOnDeliveryReasons.js";
import { getPriceOfOrder } from "../../../../core/schema/utils/getPriceOfOrder.js";
import type { Cart } from "./Cart.js";
import { CartStep, cartStepOrderMap } from "./CartStep.js";

export function cartWithAppliedProperties<T extends Cart>(cart: T): T {
	const currentCartStep = cartStepOrderMap[cart.step];
	const branch = cart.order.deliveryType !== undefined ? deliveryTypeToBranchMap[cart.order.deliveryType] : undefined;

	const cantPayOnDeliveryReasons = getCantPayOnDeliveryReasons(cart.order, PriceLevel.Basic, branch ?? null);
	if (cantPayOnDeliveryReasons.length > 0) {
		if (cart.order.payment?.type === PaymentType.OnDelivery) {
			cart.order.payment.type = undefined;

			if (currentCartStep > cartStepOrderMap[CartStep.PaymentDelivery]) {
				cart.step = CartStep.PaymentDelivery;
			}
		}
	}

	const price = getPriceOfOrder(cart.order, PriceLevel.Basic, branch ?? null);
	const isFree = price.totalPrice.withTax - getAppliedVoucherValue(cart.order.appliedVoucher, price) <= 0;
	if (cart.order.payment && cart.order.payment.type !== PaymentType.OnDelivery && isFree) {
		cart.order.payment.type = PaymentType.OnDelivery;
	}

	return {
		...cart,
		properties: {
			cantPayOnDeliveryReasons,
			isFree,
		},
	};
}
