import { Branch } from "./Branch.js";
import { DeliveryType } from "./DeliveryType.js";

export const deliveryTypeToBranchMap = {
	[DeliveryType.DeliveryToAddress]: undefined,
	[DeliveryType.Online]: undefined,
	[DeliveryType.PickUpCakovice]: Branch.Cakovice,
	[DeliveryType.PickUpCentral]: Branch.Central,
	[DeliveryType.PickUpPetriny]: Branch.Petriny,
} satisfies Record<DeliveryType, Branch | undefined>;
