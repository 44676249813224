import type { Address } from "../Address.js";
import type { Branch } from "../Branch.js";
import { CantPayOnDeliveryReason } from "../CantPayOnDeliveryReason.js";
import type { Order } from "../Order.js";
import type { PriceLevel } from "../PriceLevel.js";
import { ProductOption, productOptionKeyMap } from "../ProductOption.js";
import { maximumPriceForPaymentOnDelivery } from "../maximumPriceForPaymentOnDelivery.js";
import { getPriceOfOrder } from "./getPriceOfOrder.js";
import { isProductOptionUsed } from "./isProductOptionUsed.js";

const ignoreProductOptionsForOnlinePayment: ProductOption[] = [ProductOption.Numbers, ProductOption.Filling];

export function getCantPayOnDeliveryReasons(
	order: Pick<Order, "products" | "vouchers">,
	priceLevel: PriceLevel,
	destination: Branch | Address | null,
): CantPayOnDeliveryReason[] {
	const cartPrice = getPriceOfOrder(order, priceLevel, destination);

	const cantPayOnDeliveryReasons: CantPayOnDeliveryReason[] = [];

	const totalPriceTooHigh = cartPrice.totalPrice.withTax > maximumPriceForPaymentOnDelivery;
	if (totalPriceTooHigh) {
		cantPayOnDeliveryReasons.push(CantPayOnDeliveryReason.PriceTooHigh);
	}

	const hasMustPayOnlineOptions = order.products.some((product) =>
		Object.values(ProductOption).some((option) => {
			if (!product.options) {
				return false;
			}
			if (ignoreProductOptionsForOnlinePayment.includes(option)) {
				return false;
			}
			return isProductOptionUsed(option, product.options[productOptionKeyMap[option]]);
		}),
	);
	if (hasMustPayOnlineOptions) {
		cantPayOnDeliveryReasons.push(CantPayOnDeliveryReason.HasMustPayOnlineOption);
	}

	return cantPayOnDeliveryReasons;
}
