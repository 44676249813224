import { DeliveryType } from "../DeliveryType.js";
import { Locale, type LocalizedString } from "../Locale.js";

export const deliveryTypeNameMap = {
	[DeliveryType.DeliveryToAddress]: {
		[Locale.cs]: "Rozvoz na adresu",
	},
	[DeliveryType.Online]: {
		[Locale.cs]: "Online",
	},
	[DeliveryType.PickUpCakovice]: {
		[Locale.cs]: "Vyzvednutí na pobočce – Čakovice",
	},
	[DeliveryType.PickUpCentral]: {
		[Locale.cs]: "Vyzvednutí na centrále",
	},
	[DeliveryType.PickUpPetriny]: {
		[Locale.cs]: "Vyzvednutí na pobočce – Petřiny",
	},
} satisfies Record<DeliveryType, LocalizedString>;
