import type { Address } from "../Address.js";
import { type DeliveryDetails, deliveryInfoPerZoneMap } from "../deliveryInfoPerZoneMap.js";

// https://vdp.cuzk.gov.cz/vdp/ruian/spravniobvody?sort=&ohradaId=&nespravny=&nazevSp=&search=&page=1
const pragueAdministrativeDistrictCodeToZone: Record<string, keyof typeof deliveryInfoPerZoneMap> = {
	"19": 2, // Praha 1
	"27": 2, // Praha 2
	"35": 1, // Praha 3
	"43": 3, // Praha 4
	"51": 2, // Praha 5
	"60": 2, // Praha 6
	"78": 1, // Praha 7
	"86": 1, // Praha 8
	"94": 1, // Praha 9
	"108": 2, // Praha 10
	"116": 3, // Praha 11
	"124": 3, // Praha 12
	"132": 3, // Praha 13
	"141": 2, // Praha 14
	"159": 3, // Praha 15
	"205": 3, // Praha 16
	"213": 3, // Praha 17
	"221": 1, // Praha 18
	"167": 1, // Praha 19
	"175": 2, // Praha 20
	"183": 3, // Praha 21
	"191": 3, // Praha 22
};

export function getDeliveryDetails(address: Address): DeliveryDetails | "unreachable" | null {
	const { smartFormAddress } = address;
	if (!smartFormAddress) {
		return null;
	}
	/*
	 * CITY_AREA_3_CODE obsahuje kód správního obvodu Prahy.
	 * Pokud bude rozvoz někdy mimo Prahu, bude se muset asi nějak dobře rozvětvit.
	 */
	if (!smartFormAddress.CITY_AREA_3_CODE) {
		return "unreachable";
	}
	const zone = pragueAdministrativeDistrictCodeToZone[smartFormAddress.CITY_AREA_3_CODE];
	if (!zone) {
		return "unreachable";
	}
	return deliveryInfoPerZoneMap[zone];
}
