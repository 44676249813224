import type { Order } from "../Order.js";
import type { OrderPricesWithUnknownDeliveryPrice } from "../OrderPrices.js";
import { getVoucherDiscount } from "./getVoucherDiscount.js";

export function getAppliedVoucherValue(
	appliedVoucher: Order["appliedVoucher"],
	price: OrderPricesWithUnknownDeliveryPrice,
): number {
	return appliedVoucher ? getVoucherDiscount(appliedVoucher.value, price.totalPrice.withTax) : 0;
}
