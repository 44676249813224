import type { Order } from "../../../../core/schema/Order.js";
import { CartStep } from "./CartStep.js";

export const fieldsToFillInPerStep = {
	[CartStep.Overview]: [] as const,
	[CartStep.PaymentDelivery]: ["payment", "deliveryDate", "deliveryType"] as const,
	[CartStep.Address]: ["customer", "price"] as const,
	[CartStep.ReadyToSend]: ["tenantId"] as const,
} satisfies Partial<Record<CartStep, (keyof Order)[]>>;

export type FieldsToFillInPerStep<Step extends CartStep> = Step extends keyof typeof fieldsToFillInPerStep
	? (typeof fieldsToFillInPerStep)[Step][number]
	: never;
